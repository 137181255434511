import React from 'react';

export class Header extends React.Component {
    render() {
        return (
            <div class="header">
                <h1>
                    The Other Half's News
                </h1>
            </div>
        )
    }
}
