import React from 'react';
import './index.css';


export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="footer">
                <p>Created By Nate Kirschner</p>
            </div>
        )
    }
}